import CmsImage, { ImageFormat } from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage from "@/components/cms/cmsImage/optimizeStrapiImage";
import { PbContentImageProps } from "@/components/content/pbContentImage/pbContentImage";
import { ImagePlaceholder } from "@/components/util/imagePlaceholder";
import OverlayActionButtons from "@/components/util/overlayActionButtons";
import useCmsMediaManagerSelector from "@/hooks/useCmsMediaManagerSelector";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch } from "@/store/store";
import { StrapiUploadFile } from "@/types/strapi";
import { MEDIA_TYPE_IMG, MEDIA_TYPE_SVG } from "@/utils/constants";

interface CmsContentImageProps extends Omit<PbContentImageProps, "width" | "height"> {
  format?: ImageFormat;
  width?: string | number;
  height?: string | number;
  onChange?: (img?: StrapiUploadFile) => void;
}

export default function CmsContentImage(props: CmsContentImageProps) {
  const dispatch = useAppDispatch();
  const { openMediaManagerSelector } = useCmsMediaManagerSelector(
    (file: StrapiUploadFile) => {
      if (props.onChange) {
        props.onChange(file);
      } else {
        dispatch(
          updateAttributeAction({
            attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldImage}`,
            value: file,
          })
        );
      }
    }
  );

  const onDelete = () => {
    if (props.onChange) {
      props.onChange();
    } else {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.cmsPosition}].${props.cmsFieldImage}`,
          value: null,
        })
      );
    }
  };

  return (
    <OverlayActionButtons
      onDelete={props.img ? onDelete : undefined}
      onClick={() => {
        openMediaManagerSelector([MEDIA_TYPE_IMG, MEDIA_TYPE_SVG]);
      }}
    >
      {props.img && props.format ? (
        <CmsImage
          height={props.height}
          image={props.img}
          format={props.format}
        />
      ) : null}
      {props.img && !props.format ? (
        <OptimizeStrapiImage
          image={props.img}
          isMobile={props.isMobile}
          skipOptimization={props.skipOptimization}
          widthLimitSettings={props.widthLimitSettings}
        >
          <CmsImage
            image={props.img}
            height={props.height}
            width={props.width}
            objectFit={props.objectFit}
            objectPosition={props.objectPosition}
            responsive={props.responsive}
            format={"medium"}
          ></CmsImage>
        </OptimizeStrapiImage>
      ) : null}
      {!props.img ? (
        <ImagePlaceholder
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "grey",
          }}
          placeholderWidth={props.placeholderWidth ?? 315}
          placeholderHeight={props.placeholderHeight ?? 100}
        />
      ) : null}
    </OverlayActionButtons>
  );
}
